import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import React, { useContext } from 'react'

import EpisodeDetail from '../components/EpisodeDetail'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'
import AppContext from '../context/AppContext'

function EpisodePage(props) {
  const { number, title } = props.pageContext
  const { podcasts } = useContext(AppContext)
  const episode = _get(podcasts, number - 1, {})
  const rest = podcasts.filter(p => p.id !== episode.id)

  return (
    <Layout>
      <SEO title={title} />
      <PageContent>
        <Header />
        <EpisodeDetail podcast={episode} morePodcasts={rest} />
      </PageContent>
    </Layout>
  )
}

export default EpisodePage
